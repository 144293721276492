<template>
  <Dialog visible="isVisible" :style="{width: '450px'}" header="Transaction Details" :modal="true" class="p-fluid">
    <div class="field">
      <label for="date">Category</label>
      <Dropdown v-model="transaction.category_id" :options="categoryList" optionLabel="description" optionValue="id" placeholder="Select" :editable="false"/>
    </div>
    <div class="field">
      <label for="date">Account</label>
      <Dropdown v-model="transaction.account_id" :options="accountList" optionLabel="type" optionValue="id" placeholder="Select" :editable="false"/>
    </div>
    <div class="field">
      <label for="date">Date</label>
      <Calendar id="date" :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" :selectOtherMonths=true v-model="transaction.date" required="true" :class="{'p-invalid': transactionSubmitted && !transaction.date}"></Calendar>
    </div>
    <div class="field">
      <label for="description">Description</label>
      <InputText id="description" v-model.trim="transaction.description" required="true" :class="{'p-invalid': transactionSubmitted && !transaction.description}" />
    </div>
    <div class="field">
      <label for="cost">Cost</label>
      <InputText id="cost" v-model.trim="transaction.cost" required="true" :class="{'p-invalid': transactionSubmitted && !transaction.cost}" />
    </div>
    <div class="field">
      <p class="mb-1">Posted</p>
        <InputSwitch v-model="transaction.posted" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="cancel"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveTransaction" />
    </template>
  </Dialog>
</template>

<script>
  import TransactionService from '../service/TransactionService'
  import AccountService from '../service/AccountService'
  import CategoryService from '../service/CategoryService'

  export default {
    props: {
      isVisible: { default: false, type: Boolean },
      transaction_id: { type: Number }
    },
    emits: ['refresh', 'visible'],
    data() {
      return {
        transaction: {},
        transactionSubmitted: false,
        categoryList: [],
        accountList: [],
      }
    },
    created() {
      this.transactionService = new TransactionService()
      this.accountService = new AccountService()
      this.categoryService = new CategoryService()
    },
    mounted() {
      if (this.transaction_id) {
        this.transactionService.getTransaction(this.transaction_id).then(data => {
          this.transaction = data
          this.transaction.category_id = data.category.id
          this.transaction.account_id = data.account.id
          this.transaction.date = this.formatDate(data.date)
        })
      } else {
        this.transaction = {
          description: '',
          posted: false
        }
      }
      this.accountService.getAccounts().then(data => this.accountList = data)
      this.categoryService.getCategories().then(data => this.categoryList = data)
    },
    methods: {
      cancel() {
        this.$emit('visible', false)
      },
      formatDate(value) {
        const newDate = new Date(value)
        return newDate.toLocaleDateString('en-ca', {timeZone: 'UTC'})
      },
      saveTransaction() {
        this.transactionSubmitted = true
        if (this.transaction.description.trim() && this.transaction.date && this.transaction.cost) {
          if (this.transaction.id) {
            console.log(this.formatDate(this.transaction.date))
            this.transaction.date = this.formatDate(this.transaction.date)
            this.transactionService.editTransaction(this.transaction).then(data => {
              this.$emit('refresh')
              this.$emit('visible', false)
              console.log(data)
            })
          } else {
            this.transaction.date = this.formatDate(this.transaction.date)
            this.transactionService.addTransaction(this.transaction).then(data => {
              this.$emit('refresh')
              this.$emit('visible', false)
              console.log(data)
            })
          }
        }
      }
    }
  }
</script>
