import axios from 'axios'
import authHeader from './AuthHeader';

export default class AccountService {
    getAccounts() {
        return axios.get(process.env.VUE_APP_BASEURL + '/accounts', { headers: authHeader() }).then(res => res.data);
    }

    getAccountTransactions(account_id, start_date, end_date) {
        return axios.get(process.env.VUE_APP_BASEURL + '/accounts/' + account_id + '/transactions?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() }).then(res => res.data)
    }
}
