import axios from 'axios'
import authHeader from './AuthHeader';

export default class CategoryService {

    getCategories() {
        return axios.get(process.env.VUE_APP_BASEURL + '/categories', { headers: authHeader() }).then(res => res.data);
    }

    getEstimatesByCategory(category_id, start_date, end_date) {
        return axios.get(process.env.VUE_APP_BASEURL + '/categories/' + category_id + '/estimates?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() }).then(res => res.data);
    }

    getCategoryEstimate(category_id, start_date, end_date) {
        var results = {}
        results = axios.get(process.env.VUE_APP_BASEURL + '/categories/' + category_id + '/estimate?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() })
            .then(res => res.data)
            .catch(error => {
                if (error.response.data.detail == 'Category not found') {
                    return error.response.data.detail
                }
            });
        return results
    }

    addCategory(newCategory) {
        return axios.post(process.env.VUE_APP_BASEURL + '/categories', newCategory, { headers: authHeader() }).then(res => res.data);
    }

    editCategory(category) {
        return axios.put(process.env.VUE_APP_BASEURL + '/categories/' + category.id, category, { headers: authHeader() }).then(res => res.data);
    }

    getCategory(category_id) {
        return axios.get(process.env.VUE_APP_BASEURL + '/categories/' + category_id, { headers: authHeader() }).then(res => res.data);
    }
}
