import axios from 'axios'
import authHeader from './AuthHeader';

export default class TransactionService {

    getTransationsByCategory(start_date, end_date) {
        return axios.get(process.env.VUE_APP_BASEURL + '/categories/transactions?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() }).then(res => res.data)
    }

    getTransactions(start_date, end_date) {
        return axios.get(process.env.VUE_APP_BASEURL + '/transactions?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() }).then(res => res.data)
    }

    getTransactionsHistory() {
        return axios.get(process.env.VUE_APP_BASEURL + '/transactionsHistory', { headers: authHeader() }).then(res => res.data)
    }

    getTransaction(transaction_id) {
        return axios.get(process.env.VUE_APP_BASEURL + '/transactions/' + transaction_id, { headers: authHeader() }).then(res => res.data)
    }

    addTransaction(transaction) {
        return axios.post(process.env.VUE_APP_BASEURL + '/transactions', transaction, { headers: authHeader() }).then(res => res.data)
    }

    editTransaction(transaction) {
        return axios.put(process.env.VUE_APP_BASEURL + '/transactions/' + transaction.id, transaction, { headers: authHeader() }).then(res => res.data)
    }

    getTransactionsByCategoryDesc(category, start_date, end_date) {
        return axios.get(process.env.VUE_APP_BASEURL + '/categories/' + category + '/transactions?start_date=' + start_date + '&end_date=' + end_date, { headers: authHeader() }).then(res => res.data);
    }

    getExpenseVsIncome(year) {
        return axios.get(process.env.VUE_APP_BASEURL + '/transactions/income-expenses-chart?year=' + year, { headers: authHeader() }).then(res => res.data);
    }
}
